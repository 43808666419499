<script>
import GroupManager from '../../components/GroupManager/GroupManager.vue';
  
export default {
  name: "GroupsPage",

  components: {GroupManager},

}
</script>


<template>
  <div class="groups-page">
    <GroupManager></GroupManager>
  </div>
</template>


<style scoped>
.groups-page {
  display: flex;
  width: 100%;
  justify-content: center;
}
</style>